body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  font-size: 14px;
  font-weight: 400;
}
body {
  height: 100%;
  margin: 0px;
  font-size: 14px;
  font-family: AvenirNextLTPro-Regular, OpenSans-Light, Lato, sans-serif;
}
#root {
  height: 100%;
}

select.ui.dropdown {
  height: 33px;
}

/*
 img {
  border: 1px solid #ddd; --  Gray border
  border-radius: 4px; -- Rounded border 
  padding: 0px; -- Some padding 
  width: 100px; -- Set a small width 
  height: 60px; -- Set a small width 
}
*/

/* Add a hover effect (blue shadow) */
/*
img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
} */
